import { css, html, LitElement } from "lit";
import { property } from "lit/decorators/property.js";
import { state } from "lit/decorators/state.js";
import { styleMap } from "lit/directives/style-map.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import "../../shared/components/progress-bar";
import "../beam-partner-logos";
import { cssReset } from "../../shared/css-reset";
import { defineCustomElement } from "../../shared/defineCustomElement";
import { _cssVariableMap } from "../../shared/fragments/css-variable-map";
import { AsyncController } from "../../shared/controllers/async-controller";
import { getImpact } from "../../api-sdk/v3/routes";
import { _errorMessage } from "../../shared/fragments/error-message-template";
import { _loading } from "../../shared/fragments/loading-template";
import { defineCustomText, useCustomText } from "../../shared/fragments/custom-text";
import { DEFAULT_BASE_URL, SUPPORTED_LANGUAGES, WIDGET_NAMES } from "../../api-sdk/constants";
import { enforceConfig } from "../../shared/enforce-config";
import { BeamError, MissingConfig } from "../../utils/beam-errors";
import { TNumericId } from "../../shared/types";
import { cssCardGrid } from "../../shared/fragments/css-card-grid";
import { LANGUAGES } from "../../api-sdk/types";
import { localizeUserString } from "../../shared/localize";
import { progressBarConfigDefaults } from "../../shared/components/progress-bar";
import { partnerLogosConfigDefaults } from "../beam-partner-logos";
import { strings } from "./strings";

type RequiredConfig = {
  apiKey: string;
  chainId: TNumericId;
};

type IAPIV3NonprofitImpact = Awaited<ReturnType<typeof getImpact>>["community"][0];

/**
 * Beam Impact Overview Widget
 * Displays a grid of cards highlighting progress against funding goals
 * for the non-profits the chain is supporting.
 */
export class BeamCommunityImpact extends LitElement {
  static tagName = "beam-community-impact";

  @property({ type: String }) public apiKey?: RequiredConfig["apiKey"];

  @property({ type: Number }) public chainId?: RequiredConfig["chainId"];

  @property({ type: String }) public baseUrl = DEFAULT_BASE_URL;

  @property({ type: String }) public cardStyle: "icon" | "image" = "image";

  @property({ type: String }) public lang: LANGUAGES = "en";

  @property({ type: Boolean }) public debug = false;

  @property({ type: Boolean }) public draftConfig = false;

  @state() private selectedFilter: string | null = null;

  get configLang() {
    return SUPPORTED_LANGUAGES[this.lang] || "en";
  }

  private getImpactData = async () => {
    if (!enforceConfig<RequiredConfig>(["apiKey", "chainId"], this)) throw new MissingConfig();
    const res = getImpact({
      baseUrl: this.baseUrl,
      headers: {
        authorization: `Api-Key ${this.apiKey}`,
      },
      queryParams: {
        chainId: this.chainId,
        personalImpactLimit: 0,
        widgetName: WIDGET_NAMES.community_impact,
        version: "1.0.0",
        lang: this.configLang,
        draftConfig: this.draftConfig,
      },
    });

    return res;
  };

  private impactDataController = new AsyncController<typeof this.getImpactData>(this, this.getImpactData);

  async updated(changedProperties: Map<string, unknown>) {
    // Reload nonprofit list on change of any of these props:
    // Also fires on first load as props go from undefined => value
    const requireNewDataProps = ["chainId", "baseUrl", "lang"];
    for (const prop of requireNewDataProps) {
      if (changedProperties.has(prop)) {
        this.impactDataController.exec();
        break;
      }
    }
  }

  private renderFilterTabs({ nonprofits }: { nonprofits: IAPIV3NonprofitImpact[] }) {
    const uniqueFilters = Array.from(new Set(nonprofits.flatMap((n) => n.filters || [])));
    if (uniqueFilters.length === 0) return "";
    const handleSeeAllFocus = () => {
      this.selectedFilter = null;
    };
    const handleFilterFocus = (filter: string) => {
      if (this.selectedFilter === filter) {
        this.selectedFilter = null;
      } else {
        this.selectedFilter = filter;
      }
    };
    return html` <div
      class="beam-filter-tabs"
      part="filters"
      style="display: inline-flex; flex-wrap: wrap; gap: 0.5em 1em"
    >
      <span
        tabindex="0"
        style="${styleMap({
          "padding-bottom": "1px",
          "border-bottom-width": "2px",
          "border-bottom-style": "solid",
          "border-bottom-color": this.selectedFilter === null ? "currentColor" : "transparent",
        })}"
        @click=${handleSeeAllFocus}
        @keydown=${(evt: KeyboardEvent) => {
          if (["Enter", " "].includes(evt.key)) {
            evt.preventDefault();
            handleSeeAllFocus();
          }
        }}
        >${strings[this.configLang].seeAll()}</span
      >
      ${uniqueFilters.map(
        (filter: string) =>
          html`<span
            tabindex="0"
            @click=${() => handleFilterFocus(filter)}
            @keydown=${(evt: KeyboardEvent) => {
              if (["Enter", " "].includes(evt.key)) {
                evt.preventDefault();
                handleFilterFocus(filter);
              }
            }}
            style="${styleMap({
              "padding-bottom": "1px",
              "border-bottom-width": "2px",
              "border-bottom-style": "solid",
              "border-bottom-color": this.selectedFilter === filter ? "currentColor" : "transparent",
            })}"
            >${filter}</span
          >`
      )}
    </div>`;
  }

  private renderImageCard = (data: IAPIV3NonprofitImpact) => {
    const nonprofitImageAltText = `${data.nonprofit?.name} image`;
    const learnMoreLinkAriaLabel = `Learn more about ${data.nonprofit?.name}`;

    return html`
      <div class="image-card" part="nonprofit-card" style="display: flex; flex-direction: column;">
        <div
          class="image-card-image-box"
          style="background-image: url('${data.imageUrl}');"
          aria-label="${nonprofitImageAltText}"
        >
          <p class="image-card-cause">${localizeUserString(this.configLang, data.nonprofit?.cause || "")}</p>
          <h4 class="image-card-name">${localizeUserString(this.configLang, data.nonprofit?.name)}</h4>
        </div>
        <div
          style="padding: 5px 15px 15px; flex: 1 1 auto; border-radius: 0px; border: none; display: flex; flex-direction: column;"
        >
          <div class="image-card-progress" style="display: flex; align-items: center;">
            <beam-progress-bar style="flex-grow: 1;" value="${data.impact?.goalProgressPercentage}"></beam-progress-bar>
            <span class="image-card-progressText" style="margin-left: 20px;"
              >${localizeUserString(this.configLang, data.impact?.goalProgressText)}</span
            >
          </div>
          <div class="image-card-description">
            ${unsafeHTML(localizeUserString(this.configLang, data.impact?.description || ""))}
          </div>
          <div style="display: flex; align-items: end; flex-wrap: wrap; gap: 10px; flex: 1;" class="image-card-footer">
            <div class="image-card-goal-completion" style="flex-grow: 1;">
              ${unsafeHTML(localizeUserString(this.configLang, data.impact?.goalCompletionText))}
            </div>
            <a
              href="${data.nonprofit?.website}"
              target="_blank"
              class="image-card-link"
              style="color: var(--beam-CommunityImpact-imageCard-link-color, inherit); white-space: nowrap"
              aria-label="${learnMoreLinkAriaLabel}"
            >
              ${localizeUserString(this.configLang, data.nonprofit?.websiteLinkText)}<span class="image-card-link-arrow"
                >&nbsp;&rsaquo;</span
              >
            </a>
          </div>
        </div>
      </div>
    `;
  };

  private renderIconCard = (data: IAPIV3NonprofitImpact) => {
    const nonprofitIconAltText = `${data.nonprofit?.name} icon`;
    const learnMoreLinkAriaLabel = `Learn more about ${data.nonprofit?.name}`;

    return html`
      <div class="icon-card" part="nonprofit-card" style="display: flex; flex-direction: column;">
        <img class="icon-card-icon" src="${data.nonprofit?.causeIconUrl}" alt="${nonprofitIconAltText}" />
        <p class="icon-card-cause">${data.nonprofit?.cause}</p>
        <h4 class="icon-card-name">${data.nonprofit?.name}</h4>
        <div class="icon-card-description" part="nonprofit-card-description" style="flex: 1;">
          ${unsafeHTML(localizeUserString(this.configLang, data.impact?.description || ""))}
        </div>
        <div class="icon-card-footer" style="width: 100%; text-align: center;">
          <div class="icon-card-progress" style="display: flex; align-items: center; width: 100%;">
            <beam-progress-bar style="flex: 1 0;" value="${data.impact?.goalProgressPercentage}"></beam-progress-bar>
            <span style="text-align: right; flex: 0 1; white-space: nowrap" class="icon-card-progressText"
              >${localizeUserString(this.configLang, data.impact?.goalProgressText)}</span
            >
          </div>
          <div class="icon-card-goal-completion">
            ${unsafeHTML(localizeUserString(this.configLang, data.impact?.goalCompletionText))}
          </div>
          <a
            href="${data.nonprofit?.website}"
            target="_blank"
            class="icon-card-link"
            style="color: var(--beam-CommunityImpact-iconCard-link-color, inherit); display: block"
            aria-label="${learnMoreLinkAriaLabel}"
            >${localizeUserString(this.configLang, data.nonprofit?.websiteLinkText) ||
            strings[this.configLang].learnMore()}<span class="icon-card-link-arrow">&nbsp;&rsaquo;</span>
          </a>
          ${data.impact?.goalCompletionText === "" ? html`<div class="icon-card-goal-completion">&nbsp;</div>` : ""}
        </div>
      </div>
    `;
  };

  private renderCards = ({ nonprofits }: { nonprofits: IAPIV3NonprofitImpact[] }) => {
    return html`
      <div class="beam-nonprofit-${this.cardStyle}-cards" part="nonprofit-cards">
        ${nonprofits?.map(this.cardStyle === "image" ? this.renderImageCard : this.renderIconCard)}
      </div>
    `;
  };

  render() {
    const { selectedFilter } = this;
    const { data, error, loading } = this.impactDataController;
    if (loading) {
      return _loading();
    }
    if (error) {
      if (this.debug) {
        return _errorMessage({ error });
      }
      return "";
    }
    if (data == null) {
      if (this.debug) {
        return _errorMessage({ error: new BeamError("Missing data") });
      }
      return "";
    }
    const { community: nonprofits } = data;
    const filteredNonprofits = selectedFilter
      ? nonprofits.filter((np) => np.filters?.includes(selectedFilter))
      : nonprofits;
    return html`
      <style>
        :host {
          ${this.cssVariables.toCSS()}
        }
      </style>
      <beam-partner-logos
        part="logos"
        partnerLogoUrl="${data.chain.logoUrl}"
        partnerName="${data.chain.name}"
      ></beam-partner-logos>

      ${this.renderFilterTabs({ nonprofits })} ${this.renderCards({ nonprofits: filteredNonprofits })}
    `;
  }

  public get cssVariables() {
    const defaults: Record<string, string> = {
      "--beam-fontFamily": "inherit",
      "--beam-fontStyle": "inherit",
      "--beam-fontSize": "14px",
      "--beam-lineHeight": "1",
      "--beam-textColor": "inherit",
      "--beam-backgroundColor": "inherit",
      ...partnerLogosConfigDefaults,
      ...progressBarConfigDefaults,
      "--beam-CommunityImpact-filterTabs-textalign": "left",
      "--beam-CommunityImpact-filterTabs-marginBottom": "20px",
      /* Image Card Styles */
      "--beam-CommunityImpact-imageCard-borderColor": "currentColor",
      "--beam-CommunityImpact-imageCard-borderRadius": "0",
      "--beam-CommunityImpact-imageCard-borderWidth": "1px",
      "--beam-CommunityImpact-imageCard-backgroundColor": "inherit",
      "--beam-CommunityImpact-imageCard-foregroundColor": "inherit",
      ...defineCustomText("--beam-CommunityImpact-filterTabs", {
        fontSize: "16px",
        marginTop: "10px",
        fontWeight: "bold",
      }),
      ...defineCustomText("--beam-CommunityImpact-imageCard-cause", {
        fontSize: "14px",
        textTransform: "uppercase",
        color: "#fff",
      }),
      ...defineCustomText("--beam-CommunityImpact-imageCard-name", {
        fontSize: "22px",
        marginTop: "0",
        color: "#fff",
      }),
      ...defineCustomText("--beam-CommunityImpact-imageCard-description", {
        marginTop: "10px",
        lineHeight: "1.3",
      }),
      "--beam-CommunityImpact-imageCard-link-color": "inherit",
      "--beam-CommunityImpact-imageCard-linkArrow-display": "none",
      ...defineCustomText("--beam-CommunityImpact-imageCard-link", {
        fontSize: "12px",
      }),
      ...defineCustomText("--beam-CommunityImpact-imageCard-goalCompletion", {
        fontSize: "12px",
      }),
      "--beam-CommunityImpact-imageCard-goalCompletion-fontStyle": "italic",
      "--beam-CommunityImpact-imageCard-progress-marginTop": "10px",
      ...defineCustomText("--beam-CommunityImpact-imageCard-progressText"),
      "--beam-CommunityImpact-imageCard-footer-marginTop": "10px",
      /* Icon Card Styles */
      ...defineCustomText("--beam-CommunityImpact-iconCard-cause", {
        marginTop: "10px",
      }),
      ...defineCustomText("--beam-CommunityImpact-iconCard-name", {
        marginTop: "5px",
        fontWeight: "bold",
      }),
      "--beam-CommunityImpact-iconCard-progress-marginTop": "10px",
      ...defineCustomText("--beam-CommunityImpact-iconCard-progressText"),
      ...defineCustomText("--beam-CommunityImpact-iconCard-description", {
        marginTop: "10px",
        lineHeight: "1.3",
      }),
      "--beam-CommunityImpact-iconCard-description-textAlign": "left",
      ...defineCustomText("--beam-CommunityImpact-iconCard-goalCompletion", {
        marginTop: "10px",
      }),
      "--beam-CommunityImpact-iconCard-link-color": "inherit",
      "--beam-CommunityImpact-iconCard-linkArrow-display": "none",
      ...defineCustomText("--beam-CommunityImpact-iconCard-link", {
        marginTop: "5px",
      }),
    };

    const cardCount = this.impactDataController.data?.community.length || 0;
    const columnCount = cardCount === 3 ? 3 : this.cardStyle === "icon" ? 4 : 2;
    defaults["--beam-CommunityImpact-columns"] = columnCount.toString();
    defaults["--beam-CommunityImpact-columns-minWidth"] = this.cardStyle === "image" ? "300px" : "200px";

    const remoteConfig = this.impactDataController?.data?.config?.web?.theme || {};

    const config = { ...defaults, ...remoteConfig };

    const serializable = Object.create({
      toCSS() {
        return _cssVariableMap(this as Record<string, string>);
      },
    });

    return Object.assign(serializable, config);
  }

  static styles = [
    cssReset,
    css`
      :host {
        display: block;
        font-family: var(--beam-fontFamily, inherit);
        font-style: var(--beam-fontStyle, inherit);
        font-size: var(--beam-fontSize, inherit);
        color: var(--beam-textColor, inherit);
        background-color: var(--beam-backgroundColor, inherit);
        line-height: var(--beam-lineHeight, 1);
        word-break: normal;
      }

      .beam-filter-tabs {
        ${useCustomText("--beam-CommunityImpact-filterTabs")}
        margin-bottom: var(--beam-CommunityImpact-filterTabs-marginBottom, 10px);
        text-align: var(--beam-CommunityImpact-filterTabs-textalign, left);
      }

      .beam-nonprofit-image-cards,
      .beam-nonprofit-icon-cards {
        margin-top: 10px;
      }

      ${cssCardGrid({
        gap: "20px",
        className: "beam-nonprofit-image-cards",
        columnCount: "var(--beam-CommunityImpact-columns)",
        itemMinWidth: "var(--beam-CommunityImpact-columns-minWidth)",
      })}

      ${cssCardGrid({
        gap: "40px",
        className: "beam-nonprofit-icon-cards",
        columnCount: "var(--beam-CommunityImpact-columns)",
        itemMinWidth: "var(--beam-CommunityImpact-columns-minWidth)",
      })}

      /* Image Style Cards */

      .image-card {
        overflow-wrap: break-word;
        border-color: var(--beam-CommunityImpact-imageCard-borderColor);
        border-style: solid;
        border-width: var(--beam-CommunityImpact-imageCard-borderWidth);
        border-radius: var(--beam-CommunityImpact-imageCard-borderRadius);
        overflow: hidden;
        background-color: var(--beam-CommunityImpact-imageCard-backgroundColor);
        color: var(--beam-CommunityImpact-imageCard-foregroundColor);
      }

      .image-card-image-box {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 24px 16px;
        overflow-wrap: break-word;
        background-color: #555;
        background-blend-mode: overlay;
        background-size: cover;
        background-repeat: no-repeat;
      }

      .image-card-cause {
        ${useCustomText("--beam-CommunityImpact-imageCard-cause")}
      }

      .image-card-name {
        ${useCustomText("--beam-CommunityImpact-imageCard-name")}
      }

      .image-card-progress {
        margin-top: var(--beam-CommunityImpact-imageCard-progress-marginTop);
      }

      .image-card-progressText {
        ${useCustomText("--beam-CommunityImpact-imageCard-progressText")}
      }

      .image-card-description {
        ${useCustomText("--beam-CommunityImpact-imageCard-description")}
      }

      .image-card-link {
        ${useCustomText("--beam-CommunityImpact-imageCard-link")}
      }

      .image-card-link-arrow {
        display: var(--beam-CommunityImpact-imageCard-linkArrow-display, "none");
      }

      .image-card-goal-completion {
        ${useCustomText("--beam-CommunityImpact-imageCard-goalCompletion")}
        font-style: var(--beam-CommunityImpact-imageCard-goalCompletion-fontStyle, italic);
      }

      .image-card-footer {
        margin-top: var(--beam-CommunityImpact-imageCard-footer-marginTop);
      }

      /* Icon Style Cards */

      .icon-card {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
      }

      .icon-card-icon {
        height: 50px;
      }

      .icon-card-cause {
        ${useCustomText("--beam-CommunityImpact-iconCard-cause")}
      }

      .icon-card-name {
        ${useCustomText("--beam-CommunityImpact-iconCard-name")}
      }

      .icon-card-progress {
        margin-top: var(--beam-CommunityImpact-iconCard-progress-marginTop);
      }

      .icon-card-progressText {
        ${useCustomText("--beam-CommunityImpact-iconCard-progressText")}
        margin-left: 10px;
      }

      .icon-card-description {
        ${useCustomText("--beam-CommunityImpact-iconCard-description")}
        text-align: var(--beam-CommunityImpact-iconCard-description-textAlign, left);
      }

      .icon-card-link {
        ${useCustomText("--beam-CommunityImpact-iconCard-link")}
      }

      .icon-card-link-arrow {
        display: var(--beam-CommunityImpact-iconCard-linkArrow-display, "none");
      }

      .icon-card-goal-completion {
        ${useCustomText("--beam-CommunityImpact-iconCard-goalCompletion")}
        font-style: var(--beam-CommunityImpact-iconCard-goalCompletion-fontStyle, italic);
      }

      .icon-card-footer {
        margin-top: var(--beam-CommunityImpact-iconCard-footer-marginTop);
      }
    `,
  ];
}

defineCustomElement(BeamCommunityImpact);

declare global {
  interface HTMLElementTagNameMap {
    "beam-community-impact": BeamCommunityImpact;
  }
}
